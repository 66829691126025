@import url(https://fonts.bunny.net/css?family=montserrat:500|open-sans:500|pacifico:400|roboto:400);

body{
  background-color: #fffdfb;
  color:#1e4682;

}


.navbar {
  font-family: roboto;
  background-color: #ebf3ff;
}

.nav-link{
  color: #1e4682;
  
}

a.navbar-brand {
  color: #1e4682;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color: #8c70f0;
}

.nav-link:hover {
  color: #8c70f0;
}

.home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 10px 30px;
  background-color: #f8faff;
}

.home-content {
  flex: 1;
  padding-right: 20px;
  margin-top: 100px;
}

.home-container h1{
  padding:40px 0;
  align-content: center;
  font-family:montserrat;
}

.home-container p {
  line-height: 1.7;
  font-family:open-sans;
}

.home-image img {
  max-width: 100%;
  height: auto;
  opacity: 100%;
}

@media (max-width: 920px) {
  .home-container {
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
}

.home-container h1{
  padding:20px 0;
  align-content: center;
  font-family:montserrat;
}

.home-content {
    padding-right: 0;
  }
}

.courses-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  padding-top: 95px;
}

.course-section {
  
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.courses-heading {
  padding:20px;
  font-size: 48px;
  margin-bottom: 20px;
}

.course-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
  align-items: center;
  width: 100%; 
}

.course-container::-webkit-scrollbar {
  display: none;
}


.course-container {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.course-item {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.course-item img {
  width: 100%;
  height: 200px;
  object-fit:contain;
}

.course-name {
  padding: 10px;
  background-color: #fff;
  text-align: center;
}

.course-description {
  padding: 10px;
  background-color: #f0f0f0;
  text-align: center;
}

.nav-buttons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-button {
  left: 2px;
}

.right-button {
  right: 2px;
}

.nav-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
  transition: color 0.3s;
  margin:0;
}

.nav-button:hover {
  color: #0056b3;
}
@media (max-width: 576px) {

  .home-content{
    margin-top:80px;
  }
  .course-section {
    flex-direction: column;
    align-items: center;
  }
  .courses-heading {
    font-size: 32px;
  }

  .course-item {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .course-item img {
    height: 150px;
  }

  .course-name {
    font-size: 18px;
    padding: 5px; 
  }

  .course-description {
    font-size: 14px; 
    padding: 5px; 
  }

  .course-toggle {
    font-size: 14px; 
    padding: 5px 10px; 
  }

  .nav-button {
    display: none;
  }
}

.admission-section {
  background-color: #f8f9fa;
  padding-top: 115px;
  text-align: center;
  padding-bottom: 30px;
}

.admission-section p {
  font-size: 1.2rem;
}

.admission-steps {
  list-style-type: decimal; 
  font-size: 1.1rem;
  color: #333; 
}

.admission-steps li {
  margin-bottom: 15px; 
}

.admission-steps ul {
  list-style-type: circle; 
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 20px; 
}

.admission-steps a {
  color: #007bff; 
}

.owner-details {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.aboutcontainer {
  padding-top: 115px;
}

.owner-image {
  width: 200px;
  border-radius: 50%;
  margin-right: 20px;
}

.owner-info h4 {
  margin-bottom: 5px;
}

.owner-info p {
  font-size: 1rem;
}

.branches-container {
  text-align: center;
  margin-bottom: 40px;
}

.branch-card {
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.branches-container h2 {
  padding-top: 120px;
  padding-bottom: 50px;
}



.footer {
  background-color: #ebf3ff;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.list-unstyled li {
  margin-bottom: 8px;
}

.list-unstyled a {
  color: #fff;
  text-decoration: none;
}

.text-light {
  color: #fff !important;
}

.address {
  font-size: 0.9rem;
  color: #808080;
}



